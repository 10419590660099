import React from "react";
import "./App.css";

import Header from "./components/Header/Header";
import Hero from "./components/UI/Hero";
import Counter from "./components/UI/Counter";
import Services from "./components/UI/Services";
import About from "./components/UI/About";
import Team from "./components/UI/Team";
import Testimonial from "./components/UI/testimonial";
import Footer from "./components/UI/Footer"; 
import Contact from "./components/UI/Contact";

function App() {
  return (
    <>
    <Header />
    <Hero />
    <Counter />
    <About />
    <Services />
    <Team />
    <Testimonial />
    <Contact />
    <Footer />
    </>
  );
}

export default App;





import React from 'react'
import '../../styles/team.css'

import team01 from "../../images/team-01.png";
import ava from "../../images/ava-1.jpg"


const teamMembers = [
    {
        imgUrl: team01,
        name: 'Zakariya El Baz',
        position: 'CEO',
    },
        
    {
        imgUrl: ava,
        name: 'Fayza ',
        position: 'Femme de ménage',
    },
        
    {
        imgUrl: ava,
        name: 'Sabah',
        position: 'Femme de ménage',
    },

    {
        imgUrl: ava,
        name: 'Leila',
        position: 'Femme de ménage',
    },

    {
        imgUrl: ava,
        name: 'Hamid',
        position: 'Agent de sécurité',
    },

    {
        imgUrl: ava,
        name: 'Abderahim',
        position: 'Agent de sécurité',
    },

    {
        imgUrl: ava,
        name: 'Mehdi',
        position: 'Agent de sécurité',
    },

    {
        imgUrl: ava,
        name: 'Hamza',
        position: 'Agent de sécurité',
    },

    {
        imgUrl: ava,
        name: 'Samir',
        position: 'Agent de sécurité',
    },

    {
        imgUrl: ava,
        name: 'Said',
        position: 'Agent de sécurité',
    },
    
]
const Team = () => {
  return <section id="team" className="our__team">
    <div className="container">
        <div className="team__content">
            <h6 className="subtitle">Notre équipe</h6>
            <h2>Rencontrez <span className="highlight">notre équipe </span></h2>
        </div>

        <div  className="team__wrapper">
            {
                teamMembers.map((item,index)=>(
                    <div className="team__item" key={index}>
                <div className="team__img">
                    <img src={item.imgUrl} alt="" />
                </div>
                <div className="team__details">
                    <h4>{item.name}</h4>
                    <p className="description">{item.position}</p>



                    {/* <div className="team__member-social">
                    <span><a href="https://www.linkedin.com/in/zakarya-el-baz-b06497174/" target="_blank"><i class="ri-linkedin-line"></i></a></span>
                        <span><a href="https://www.instagram.com/g.e.s_services/"><i class="ri-instagram-line" target="_blank"></i></a></span>
                </div> */}
                </div>
            </div>
                ))
            }
        </div>
    </div>
  </section>
}

export default Team



import React from 'react'
import '../../styles/testimonial.css'

import Slider from "react-slick";

import ava01 from '../../images/ava-1.jpg'
import ava02 from '../../images/ava-2.jpg'
import ava03 from '../../images/ava-3.jpg'

const testimonial = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipTOSlide: true
    }
  return  (
    <section>
        <div id="feedback" className="container">
            <div className="slider__content-top">
                <h6 className="subtitle">Retour de nos clients</h6>
                <h2>Fait confiance à plus de  <span className="highlight">40 clients</span></h2>
            </div>

            <div className="slider__wrapper">
    <Slider {...settings}>
        <div className="slider__item">
            <p className="description">"Travail de jardinage de qualité, notre jardin n'a jamais été aussi beau."
            </p>

            <div className="customer__details">
                <div className="customer__img">
                    <img src={ava01} alt="" />
                </div>

                <div>
                    <h5 className="customer__name">Rihab</h5>
                    <p className="description">Clientes </p>
                </div>
            </div>
        </div>
        <div className="slider__item">
            <p className="description">"Service de ménage impeccable, équipe ponctuelle et professionnelle."
            </p>

            <div className="customer__details">
                <div className="customer__img">
                    <img src={ava01} alt="" />
                </div>

                <div>
                    <h5 className="customer__name">Younes</h5>
                    <p className="description">CEO, ElectroHaus</p>
                </div>
            </div>
        </div>
        <div className="slider__item">
            <p className="description">"Gardiennage fiable, nous nous sentons en sécurité."
            </p>

            <div className="customer__details">
                <div className="customer__img">
                    <img src={ava01} alt="" />
                </div>

                <div>
                    <h5 className="customer__name">Assia </h5>
                    <p className="description">Clientes</p>
                </div>
            </div>
        </div> 

        <div className="slider__item">
            <p className="description">"Votre équipe de sécurité a assuré un événement en toute tranquillité. Leur professionnalisme était rassurant."
            </p>

            <div className="customer__details">
                <div className="customer__img">
                    <img src={ava01} alt="" />
                </div>

                <div>
                    <h5 className="customer__name">Salma </h5>
                    <p className="description">CEO ,EventPro</p>
                </div>
            </div>
        </div> 


    </Slider>
            </div>
        </div>
    </section>
  )
}

export default testimonial
import React from 'react'
import "../../styles/contact.css";

const contactData = [
    {
        icon: 'ri-mail-line',
        title: 'INFO.GROUPELBAZ@gmail.com',
        link: 'mailto:INFO.GROUPELBAZ@gmail.com',
    },
    {
        icon: 'ri-whatsapp-line',
        title: '+212 6 99 82 79 50',
        link: 'https://wa.me/0699827950',
    },
    {
        icon: 'ri-instagram-line',
        title: 'GES',
        link:'https://www.instagram.com/g.e.s_services/',
    },
    {
        icon: 'ri-phone-line',
        title: '+212 6 99 82 79 50',
        link: 'Tel:+212699827950',
    },
]
const Contact = () => {
  return (
    <section id="contact">
        <div className="container">
            <div className="contact__wrapper">
                <div className="contact__content">
                    <h6 className="subtitle">Travaillons ensemble</h6>
                    <h2>Contactez <span className="highlight">Nous</span></h2>
                </div>
                <div className="contact__item-wrapper">
                {contactData.map((item, index)=>(
                        <div className="contact-item" key={index}>
                        
                        <span className="contact-icon">
                        <i class={item.icon}></i>
                        </span>  
                                            
                        <h4 className="contact-title">
    <a href={item.link} target="_blank">{item.title}</a>
</h4>
                       
                        
                    </div>            
                    ))}
                </div>
            </div>
        </div>       
    </section>
  )
}

export default Contact
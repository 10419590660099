import React from 'react'
import '../../styles/services.css'


const serviceData = [
  {
    path: "ménage",
    icon: 'ri-brush-line',
  title: 'Ménage',
  desc: 'Le service de ménage vise à maintenir des espaces intérieurs propres et bien entretenus, offrant une solution complète pour un environnement propre et sain.',
  },

  {
    path: "jardinnage",
    icon: 'ri-scissors-line',
  title: 'Jardinage',
  desc: 'Ce service de jardinage se concentre sur l\'entretien et l\'aménagement d\'espaces extérieurs, offrant des solutions pour un espace vert soigné et attrayant.',
  },

  {    
    path: "gardiennage",
    icon: 'ri-lock-password-fill',
  title: 'Gardiennage',
  desc: 'Agent de sécurité / Sécurité des évènements / Sécurité rapprochée ( V.I.P ) / Vidéo-surveillance / Maître-chien.',
  },
  {
    path: "protection",
    icon: 'ri-file-shield-2-line',
  title: ' L\'installation de tous les outils de protection',
  desc: 'Ce service consiste à mettre en place tous les dispositifs de protection nécessaires pour assurer la sécurité de votre entreprise ou de vos biens..',
  },

  
]

const Services = () => {
  return <section id="services">
    <div className="container">
        <div className="service__top-content">
            <h6 className="subtitle">Nos Services</h6>
            <h2>Gagnez du temps dans la gestion de votre entreprise</h2>
            <h2 className="highlight">avec notre service exceptionnel</h2>
        </div>

        <div className="service__item-wrapper">
           {
            serviceData.map((item,index)=>(
              <div id={item.path}className="services__item" key={index} >
              <span className="service__icon">
                <i class={item.icon}></i>
                </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
          </div>
            ))
           }
        </div>
    </div>
  </section>
}

export default Services;


import React from 'react'

import  '../../styles/counter.css'

const counterData = [
    {
        number: 20,
        text:'Clients'
    },
    {
        number: 10,
        text:'Projets en cours'
    },
    {
        number: 40,
        text:'Projets terminés'
    },
    
]

const Counter = () => {
  return(
<section className="counter" id="projects">
    <div className="container">
        <div className="counter__wrapper">
            {
                counterData.map((item,index)=>(
            <div className="counter__item" >
                <h3 className="counter__number">{item.number}+</h3>
                <h4 className="counter__title">{item.text}</h4>   
            </div>
                ))
            }
        </div>
    </div>
  </section>
  ); 
}

export default Counter
import React from "react";
import "../../styles/about.css";

import aboutImg from '../../images/about-us.png'




const chooseData = [
    {
    icon: 'ri-wifi-line',
    title: 'Premier processus de travail',
    desc:"Group Elbaz Security propose des services impeccables et une vaste expérience dans le gardiennage, le ménage, le jardinage et l'installation de dispositifs de protection." 
    },

    {
        icon: 'ri-team-line',
        title: 'Équipe dévouée',
        desc:"Leur équipe d'experts passionnés se consacre au bien-être de leurs clients en offrant des solutions de sécurité complètes." 
    },

    {
        icon: 'ri-lock-line',
        title: 'Sécurité Complète',
        desc:"Ils vous encouragent à assurer la protection de votre entreprise en optant pour leurs nouveaux services de haute qualité." 
    },
    
]

const About = () => {
  return( 
     <section id="about">
    <div className="container">
        <div className="about__wrapper">
            <div className="about__content">
            <h6 className="subtitle">Pourquoi nous choisir</h6>
            <h2>"Nous créons la perfection" </h2>
            <h2 className="highlight">résume notre engagement envers l'excellence</h2>
            <p className="description about__content-desc">Group Elbaz Security propose des services impeccables en gardiennage, ménage, jardinage et installation de dispositifs de protection. Leur équipe d'experts se dévoue au bien-être des clients, garantissant la sécurité et la protection de leur entreprise. Contactez-les pour découvrir leurs nouvelles offres de qualité.
            </p>
            
            <div className="choose__item-wrapper">
                {chooseData.map((item, index)=>(
                        <div className="choose__us-item" key={index}>
                        <span className="choose__us-icon">
                        <i class={item.icon}></i>
                        </span>
                        <div>
                        <h4 className="choose__us-title">{item.title}</h4>
                        <p className="description">{item.desc}</p>
                        
                        </div>            
                    </div>
                    ))}
            </div>
            
            </div>
            <div className="about__img">
                <img src={aboutImg} alt="" />
            </div>
        </div>
    </div>
  </section>
  );
};

export default About;
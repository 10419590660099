
import React from "react";
import "../../styles/Hero.css";
import heroDarkImg from '../../images/hero-img.png'
import GES from '../../images/GES.pdf'

const Hero = () => {
  return (
    <section id="hero" className="hero__section">
        <div className="container">
            <div className="hero__wrapper">
                <div className="hero__content">
                <div>
                    <h2>Groupe Elbaz Sécurity</h2>
                    <h2 className="highlight">est votre partenaire de confiance</h2>
                </div>
                <p className="description">Group Elbaz Security offre des services de sécurité, de nettoyage, de jardinage et d'installation d'outils de protection avec une équipe d'experts dévouée au bien-être des clients.</p>
                <div className="hero__btns">
                    <button  className="primary__btn"><a href="#contact">Contactez-nous</a></button>
                    <button className="secondary__btn"><a href={GES} download={GES}>En savoir plus</a></button>
                </div>
                </div>
                <div className="hero__img">
                    <img src={heroDarkImg} alt="hero-img" />
                </div>
                </div>
            </div>
    </section>
    );
};

export default Hero;
import React from 'react'
import '../../styles/footer.css'
import LOGO from "../../images/LOGO.png";


const quickLinks02=[
    {
        path:'#ménage',
        display: 'Ménage',
    },
    {
        path:'#jardinnage',
        display: 'Jardinage',
    },
    {
        path:'#gardiennage',
        display: 'Gardiennage',
    },
    {
        path: '#protection',
        display: 'L\'installation de tous les outils de protection',
    },
];
const Footer = () => {
      return <footer className="footer">
            <div className="container">
                <div className="footer__wrapper">
                <div className="logo__img">
                    <img src={LOGO} alt="LOGO" />
                    </div>
                    <div className="footer__logo">
                    
                        <h2>GES</h2>
                        <p className="description">Travaillez avec nous</p>
                        <p className="small__text description">
                        Group Elbaz Security offre des services de sécurité, de nettoyage, de jardinage et d'installation d'outils de protection avec une équipe d'experts dévouée au bien-être des clients.Ils encouragent à assurer la sécurité de votre entreprise en profitant de leurs nouveaux services de haute qualité.
                        </p>
                        </div>
                    {/*    <div className="footer__quick-links">
                            <h3 className="quick__links-title">Solutions</h3>
                            <ul className="quick__links">
                                {quickLinks01.map((item, index)=>(
                                    <li className="quick__link-item" key={index}>
                                        <a href={item.path}>{item.display}</a>
                                    </li>
                                ))
                                }
                            </ul>
                            </div> */}
                           

                    <div className="footer__quick-links">
                            <h3 className="quick__links-title">Services</h3>
                            <ul className="quick__links">
                                {quickLinks02.map((item, index)=>(
                                    <li className="quick__link-item" key={index}>
                                        <a href={item.path}>{item.display}</a>
                                    </li>
                                ))
                                }
                            </ul>
                    </div>

                </div>

                <p className="copyright">Copyright < i class="ri-copyright-line"></i> 2023, developed by RIHAB EL MACHTI.</p>
            </div>
  </footer>
}

export default Footer


 import React, {useEffect, useRef} from "react";

 import  './Header.css'

 const nav__links =[
 
  {
    path: '#about',
    display: 'La société'
  },
  {
    path: '#services',
    display: 'Nos services'
  },
  {
    path: '#team',
    display: 'Notre équipes'
  },
 
  {
    path: '#contact',
    display: 'Contactez-nous'
  },
 ]
 const Header = () => {

  const headerRef = useRef(null)

  const menuRef = useRef(null)

  const headerFunc = ()=>{
    if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
      headerRef.current.classList.add('header__shrink')
    } else {
      headerRef.current.classList.remove('header__shrink')
    }
  }


  useEffect(()=>{
    window.addEventListener('scroll', headerFunc)

    return ()=> window.removeEventListener('scroll', headerFunc)
  },[])


  const handleClick = (e) => {
    e.preventDefault();

    const targetAttr = e.target.getAttribute("href");

    const location = document.querySelector(targetAttr).offsetTop;

    window.scrollTo({
      left: 0,
      top: location - 80,
    });
  };


  const toggleMenu = ()=> menuRef.current.classList.toggle("menu__active");



   return (
     <header  className="header" ref={headerRef}>

    <div className="container">
      <div className="nav__wrapper">
        <div className="logo">
          <a href="hero"><h2>GES</h2></a>  
        </div>
        {/*=============Navigation================= */}
        <div className="navigation" ref={menuRef} onClick={toggleMenu}>
  <ul className="menu">
    {nav__links.map((item, index) => (
      <li className="menu_item" key={index}>
        <a 
          href={item.path} onClick={handleClick} 
          className="menu__link">
          {item.display}
        </a>
      </li>
    ))}
  </ul>
</div>
{/*==============light mode ================*/}
<div className="light__mode">
</div>
      <span className="mobile__menu" onClick={toggleMenu}>
      <i class="ri-menu-line"></i>
      </span>
    </div>
    </div>
  </header> 
   )
 }
 
 export default Header;